import { Modal } from "@/components/ui/modal.tsx";
import { Button } from "./ui/button";
import { ranchHats, wildsBg, wildsCheckImg } from "@/assets/images";
import { createQuery } from "@tanstack/solid-query";
import { ConnectSocialsService, WildsCommunicationService } from "@/api-client";
import { openLink } from "@/lib/telegram";
import { Show, Suspense } from "solid-js";

function WildsConnectContent(props: { closeModal: () => void , wildsConnected: boolean}) {

  const wildsSignedUrl = createQuery(() => ({
      queryKey: ["wildsConnectSignedUrl"],
      queryFn: () => WildsCommunicationService.getWildsSignedUrl(),
      staleTime: 4000, // We want shorts TTL and since the token is set to live 5s we reducing this result TTL
      enabled: !props.wildsConnected
    }));

  const onGo = () => {
    if (!wildsSignedUrl.data) return;
    openLink(wildsSignedUrl.data?.wildsUrl);
  };

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3 z-10">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="relative flex items-center justify-center rounded-t-xl bg-[#FFE7DF] text-center">
        <img src={wildsBg} class="w-full" />
        <div class="absolute -bottom-3">
          <h3 class="rounded-xl bg-[#3F3A38] px-6 py-1 text-sm font-bold uppercase text-white">
            Alpha Launch 1.0
          </h3>
        </div>
      </div>
      <div class="flex flex-col gap-4 rounded-b-lg bg-[#FFE7DF] p-2 pt-6">
        <div class="flex items-center justify-center px-6">
          <ul class="list-disc space-y-2 rounded-b-lg text-sm text-black">
            <li>
              Announcing the highly anticipated release of Kuroro Wilds Alpha, a
              one of a kind creature collecting action RPG!
            </li>
            <li>
              Sign up in the first 7 days and link your Telegram account to
              receive a <span class="font-bold">Limited Edition Hat</span> in
              Wilds themed after your selected Ranch starter beast!
            </li>
            <li>
              Plus, earn <span class="font-bold">300 Shards</span> when you
              complete this Quest!
            </li>
          </ul>
        </div>
        <div class="flex items-center justify-center px-4">
          <img src={ranchHats} class="h-full w-full object-contain" />
        </div>
        <div class="flex items-center justify-center pb-4">
          <Suspense fallback={"Loading..."}>
            <Show when={!props.wildsConnected}>
              <Button
                onClick={onGo}
                size="sm"
                class="eazy-chat text-outline relative z-10 min-w-[150px] border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] pt-1 text-xl leading-4 hover:opacity-70"
              >
                <div class="rounded-md"></div>
                <span class="z-10 uppercase text-white">Play Now</span>
              </Button>
            </Show>
            <Show when={props.wildsConnected}>
              <div>
                <div class="flex items-center justify-center w-full mb-3">
                  <img src={wildsCheckImg} />
                  <p class="text-green-500 font-bold">Account Connected</p>
                </div>
                <a
                  href="/play/quests"
                  class="p-2 rounded-xl eazy-chat text-outline relative z-10 min-w-[150px] border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-xl leading-4 hover:opacity-70"
                >
                  <span class="z-10 uppercase text-white">Redeem 300 shards</span>
                </a>
              </div>
            </Show>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export function WildsConnectModal(props: {
  isModalOpen: () => boolean;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
  wildsConnected: boolean;
}) {
  return (
    <Modal
      open={props.isModalOpen()}
      onOpenChange={props.setIsModalOpen}
      onPointerDownOutside={props.closeModal}
    >
      <WildsConnectContent closeModal={props.closeModal} wildsConnected={props.wildsConnected} />
    </Modal>
  );
}
