import { default as chickCoopImage } from "./chick-coop.jpg";
import { default as bitgetImage } from "./bitget.png";
import { default as bitgetGlowImage } from "./bitget-glow.png";
import { default as instagramImage } from "./instagram.png";
import { default as tatsuImage } from "./tatsu.jpg";
import { default as fishingFrenzy } from "./fishing-frenzy.jpg";
import { default as wildsConnect } from "./wilds-connect.png";

// These needs to be the IconId from the CustomQuests
export const CUSTOM_QUESTS_IMAGES = {
  ChickCoop: chickCoopImage,
  Bitget: bitgetImage,
  BitgetGlow: bitgetGlowImage,
  Instagram: instagramImage,
  Tatsu: tatsuImage,
  FishingFrenzy: fishingFrenzy,
  WildsConnect: wildsConnect,
};
